import React from 'react';
import MuiDrawer, { DrawerProps as MuiDrawerProps } from '@mui/material/Drawer';

type DrawerProps = Omit<MuiDrawerProps, 'classes' | 'sx'>;

/**
 * Renders a MUI Drawer component.
 *
 * - [MUI Drawer documentation](https://mui.com/material-ui/api/drawer/) - The 'xs' and 'classes' props are omitted,
 * please don't use them.
 */

const Drawer = React.forwardRef<HTMLDivElement, DrawerProps>(({ children, ...props }: DrawerProps, ref) => {
    return (
        <MuiDrawer ref={ref} {...props}>
            {children}
        </MuiDrawer>
    );
});

Drawer.displayName = 'Drawer';

export default Drawer;
